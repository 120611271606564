import React from "react";
import ReactPlayer from "react-player";
import "./publications.css";

export const AnnabelKajetski = () => {
  return (
    <div className="kajetski-container h-center mobile-container">
      <div className="story-title-container">
        <div className="creator">Annabel Kajetski</div>
        <div className="title">Braiding is a form of Penis Envy</div>
      </div>
      <div className="video-container h-center">
        <ReactPlayer
          url="Publications/AnnabelKajetski/Kajetski_001.mp4"
          controls={true}
          width="100%"
          height="100%"
        />
        <div className="video-legend" id="mobile-padding">
          2020
          <br />
          Installation
          <br />
          T-shirts, paper carton
        </div>
      </div>
      <img
        src="/Publications/AnnabelKajetski/Kajetski_002.jpg"
        alt="Kajetski_002"
        className="kajetski-002"
        id="mobile-padding"
      />
      <div className="kajetski-03-04-container">
        <div className="kajetski-03-04-img-container">
          <img
            src="/Publications/AnnabelKajetski/Kajetski_003.jpg"
            alt="Kajetski_003"
            className="kajetski-003"
          />
          <img
            src="/Publications/AnnabelKajetski/Kajetski_004.jpg"
            alt="Kajetski_004"
            className="kajetski-004"
          />
        </div>
        <div className="kajetski-03-04-text" id="mobile-padding">
          How does ones artistic practice change during the lock down period?
          How can we use art as a therapeutic approach? What resources do we
          have at home to work with? <br />
          <br />
          My main interest in my works lately, has been how women assumed are
          getting hit by the pandemic and how emotional work has become more
          visible during this hard time we are living in
        </div>
      </div>
      <div className="kajetski-05-container">
        <img
          src="/Publications/AnnabelKajetski/Kajetski_005.jpg"
          alt="Kajetski_005"
          className="kajetski-005"
        />
        <div className="kajetski-05-text" id="mobile-padding">
          Annabel Kajetski is a visual artist who works with immersive spacial
          installations and video art. Kajetski is currently an BA + MFA student
          at the department of Time and Space at the University of Fine Arts in
          Helsinki, Finland.
        </div>
        <a
          href="http://www.annabelkajetski.com"
          className="kajetski-homepage"
          id="mobile-padding"
        >
          www.annabelkajetski.com
        </a>
      </div>
    </div>
  );
};

export const AyaBrace = () => {
  return (
    <div className="brace-container  mobile-container">
      <div className="story-title-container">
        <div className="creator">Aya Brace</div>
        <div className="title">A Finnish Woman in the Finnish Countryside</div>
      </div>
      <div className="brace-01-container h-center">
        <img
          src="/Publications/AyaBrace/Brace_001.jpg"
          alt="Brace-001"
          className="brace-001"
        />
        <div className="brace-01-legend" id="mobile-padding">
          Clothes by Laura Väisänen throughout.
        </div>
        <div className="brace-01-text" id="mobile-padding">
          As a child, I spent my summers in the Finnish countryside.
          <br />
          <br />
          Nowadays I do not want to go back much.
          <br />
          <br />I am not the one looking, I am the one being looked at.
          <br />
          <br />
          But this is my home, this is my country, this is my language, this is
          my existence.
        </div>
      </div>
      <img
        src="/Publications/AyaBrace/Brace_002.jpg"
        alt="Brace-002"
        className="brace-002"
      />
      <img
        src="/Publications/AyaBrace/Brace_003.gif"
        alt="Brace-003"
        className="brace-003"
      />
      <div className="brace-04-container">
        <img
          src="/Publications/AyaBrace/Brace_004.jpg"
          alt="Brace-004"
          className="brace-004"
        />
      </div>
      <div className="brace-05-container">
        <img
          src="/Publications/AyaBrace/Brace_005.jpg"
          alt="Brace-005"
          className="brace-005"
        />
      </div>
      <img
        src="/Publications/AyaBrace/Brace_006.jpg"
        alt="Brace-006"
        className="brace-006"
      />
      <div className="brace-07-container">
        <img
          src="/Publications/AyaBrace/Brace_007.jpg"
          alt="Brace-007"
          className="brace-007"
        />
      </div>
      <img
        src="/Publications/AyaBrace/Brace_008.jpg"
        alt="Brace-008"
        className="brace-008"
      />
      <div className="brace-09-container">
        <img
          src="/Publications/AyaBrace/Brace_009.gif"
          alt="Brace-009"
          className="brace-009"
        />
      </div>
      <div className="brace-10-11-container">
        <img
          src="/Publications/AyaBrace/Brace_011.jpg"
          alt="Brace-011"
          className="brace-011"
        />
        <img
          src="/Publications/AyaBrace/Brace_010.jpg"
          alt="Brace-010"
          className="brace-010"
        />
      </div>
      <div className="brace-12-container h-center">
        <img
          src="/Publications/AyaBrace/Brace_012.jpg"
          alt="Brace-012"
          className="brace-012"
        />
        <div className="brace-12-text-container" id="mobile-padding">
          Aya Brace (b. 1991) is a Finnish-Ghanaian photographer, graphic design
          student, and the art director of ISLA Journal. She holds a BA (Hons)
          in Fashion Photography from the University of the Arts London.
          <br />
          <br />
          Currently based in Helsinki, Aya continues to work on her art as well
          as commissions, presenting fashion through a humanistic perspective,
          while exploring the unrivalled power of a photograph in visualising
          change and beauty in today’s world.
          <br />
          <br />
          <a href="http://www.ayabrace.com" className="brace-homepage">
            www.ayabrace.com
          </a>
        </div>
      </div>
    </div>
  );
};

export const ChristineValtonen = () => {
  return (
    <div className="valtonen-container  mobile-container">
      <div className="story-title-container">
        <div className="creator">Christine Valtonen</div>
      </div>
      <div className="valtonen-01-03-container">
        <img
          src="/Publications/ChristineValtonen/Valtonen_001.jpg"
          alt="Valtonen-001"
          className="valtonen-001"
        />
        <img
          src="/Publications/ChristineValtonen/Valtonen_002.jpg"
          alt="Valtonen-002"
          className="valtonen-002"
        />
        <img
          src="/Publications/ChristineValtonen/Valtonen_003.jpg"
          alt="Valtonen-003"
          className="valtonen-003"
        />
      </div>
      <img
        src="/Publications/ChristineValtonen/Valtonen_004.jpg"
        alt="Valtonen-004"
        className="valtonen-004"
      />
      <div className="valtonen-005-container">
        <div className="valtonen-05-text" id="mobile-padding">
          <i>
            I have documented for years destroyed and abandoned furniture,
            household appliances, as well as a variety of trash piles, which I
            have found from the environment. I actively document construction
            sites, industrial areas, the urban environment, and the strange
            objects. My interest is considering objects appearing in nature
            that, for one reason or another, have ended up somewhere they don`t
            belong.
          </i>
        </div>
        <img
          src="/Publications/ChristineValtonen/Valtonen_005.jpg"
          alt="Valtonen-005"
          className="valtonen-005"
        />
      </div>
      <img
        src="/Publications/ChristineValtonen/Valtonen_006.jpg"
        alt="Valtonen-006"
        className="valtonen-006"
      />
      <div className="valtonen-07-container">
        <img
          src="/Publications/ChristineValtonen/Valtonen_007.jpg"
          alt="Valtonen-007"
          className="valtonen-007"
        />
        <div className="valtonen-07-text" id="mobile-padding">
          <b>Christine Valtonen</b> (b.1992) is a Bachelor of Arts from Fashion
          Program at Aalto University and is currently studying on the program
          of Time and Space arts at University of Arts, Helsinki.
          <br />
          <br />
          Her work is informed first by the conditions dictated by the material
          she chooses to work with. The starting point for Valtonen’s work is
          exploring the relationship between space and material through silence,
          stagnation and incompleteness
        </div>
      </div>
      <img
        src="/Publications/ChristineValtonen/Valtonen_008.jpg"
        alt="Valtonen-008"
        className="valtonen-008"
      />
    </div>
  );
};

export const EevaSuutari = () => {
  return (
    <div className="suutari-container mobile-container">
      <div className="story-title-container">
        <div className="creator">Eeva Suutari</div>
        <div className="title">Cognitive State</div>
      </div>
      <img
        src="/Publications/EevaSuutari/Suutari_001.jpg"
        alt="suutari-001"
        className="suutari-001"
      />
      <img
        src="/Publications/EevaSuutari/Suutari_002.jpg"
        alt="suutari-002"
        className="suutari-002"
      />
      <img
        src="/Publications/EevaSuutari/Suutari_003.jpg"
        alt="suutari-003"
        className="suutari-003"
      />
      <img
        src="/Publications/EevaSuutari/Suutari_004.jpg"
        alt="suutari-004"
        className="suutari-004"
      />
      <img
        src="/Publications/EevaSuutari/Suutari_005.jpg"
        alt="suutari-005"
        className="suutari-005"
      />
      <div className="suutari-text" id="mobile-padding">
        <div className="suutari-tab-left">
          Photography
          <br />
          MUAH
          <br />
          Model
          <br />
          Clothes
        </div>
        <div className="suutari-tab-right">
          Eeva Suutari
          <br />
          Eevis Liedes
          <br />
          Vita Mir
          <br />
          Juslin Maunula
        </div>
        <br />
        <br />
        Eeva’s work combines fashion, documentary and street photography, with
        her style capturing intense cinematic moments as she looks to explore
        the subjects that enter her world. This ability to understand the right
        moment for her documentary and street photography, as well as her
        infectious energy and passion towards fashion photography has landed her
        with clients such as Stockmann, Frenn, Andiata, Marie Claire
        (Australia), L’Uomo Vogue, Pitti Immagine, Hypebeast, and many more.
        <br />
        <br />
        <a
          href="https://www.instagram.com/eevasuutari"
          className="suutari-homepage"
        >
          @eevasuutari
        </a>
      </div>
    </div>
  );
};

export const EmmaSarpaniemi = () => {
  return (
    <div className="sarpaniemi-container mobile-container">
      <div className="story-title-container">
        <div className="creator">Emma Sarpaniemi</div>
      </div>
      <div className="sarpaniemi-01-02-container">
        <img
          src="/Publications/EmmaSarpaniemi/Sarpaniemi_001.jpg"
          alt="sarpaniemi-001"
          className="sarpaniemi-001"
        />
        <img
          src="/Publications/EmmaSarpaniemi/Sarpaniemi_002.jpg"
          alt="sarpaniemi-002"
          className="sarpaniemi-002"
        />
      </div>
      <div className="sarpaniemi-03-04-container">
        <img
          src="/Publications/EmmaSarpaniemi/Sarpaniemi_003.jpg"
          alt="sarpaniemi-003"
          className="sarpaniemi-003"
        />
        <img
          src="/Publications/EmmaSarpaniemi/Sarpaniemi_004.jpg"
          alt="sarpaniemi-004"
          className="sarpaniemi-004"
        />
      </div>
      <img
        src="/Publications/EmmaSarpaniemi/Sarpaniemi_005.jpg"
        alt="sarpaniemi-005"
        className="sarpaniemi-005 h-center"
      />
      <div className="sarpaniemi-text-container h-center">
        <div className="sarpaniemi-text" id="mobile-padding">
          Emma Sarpaniemi is a Finnish photographer who graduated from the Royal
          Academy of Art, The Hague. Sarpaniemi’s playful work can be regarded
          as an expanded form of self-portraiture in which she herself is a
          character exploring different female roles and relationships with the
          people she works with. Her work has been recently featured in i-D
          Netherlands, Paper Journal, Vogue Italia and Der Greif. Sarpaniemi has
          presented her work at The Finnish Museum of Photography, Melkweg
          Amsterdam and WORM S/ash Gallery. She is currently working between The
          Netherlands and Finland.
        </div>
      </div>
    </div>
  );
};

export const GURLS = () => {
  return (
    <div className="gurls-container  mobile-container">
      <div className="story-title-container">
        <div className="creator">Gurls</div>
        <div className="title">Linda Lehtovirta and Emilia Pennanen</div>
      </div>
      <img
        src="/Publications/GURLS/GURLS_001.jpg"
        alt="Gurls-001"
        className="gurls-001 h-center"
      />
      <div className="gurls-02-container">
        <img
          src="/Publications/GURLS/GURLS_002.jpg"
          alt="Gurls-002"
          className="gurls-002"
        />
      </div>
      <img
        src="/Publications/GURLS/GURLS_003.jpg"
        alt="Gurls-003"
        className="gurls-003"
      />
      <div className="gurls-03-text" id="mobile-padding">
        GURLS is a photographic collaboration between Emilia Pennanen and Linda
        Lehtovirta. The work is a combination of mixed fragments where beauty,
        repulsion and symbols of femininity become intertwined. The work uses
        self-portraiture at the base of the work. Through looking and by
        photographing oneself the work emphasizes the relationship to one’s own
        body. Who is looking, who is photographing – and who is deciding, how
        and why?
        <br />
        <br />
        The collaboration between Pennanen and Lehtovirta is based on friendship
        and trust: a notion of being at home with another. The duo uses values
        of respect and empathy as their working method, in order to fully
        communicate with each other. The ideas merge in together in an
        understanding atmosphere. The foundation for working together lies in a
        sense of safety.
        <br />
        <br />
        The work challenges the viewer to consider the power that softness might
        entail. The collaboration draws inspiration from social media platforms
        such as Instagram and Tumblr, where the community defines their autonomy
        through selfies. GURLS as an installation aspires to be a safe space for
        everyone in the physical world. The installation draws people in to
        discover the materials and content at close. The work aims to offer a
        space for everyone without shutting anyone out.
      </div>
      <img
        src="/Publications/GURLS/GURLS_004.jpg"
        alt="Gurls-004"
        className="gurls-004"
        id="mobile-padding"
      />
      <div className="gurls-05-06-container">
        <div className="gurls-05-06-text" id="mobile-padding">
          GURLS duo is a collaborative project between artists Linda Lehtovirta
          (s. 1991) and Emilia Pennanen (s. 1990). The aim of the collaboration
          is to visually study intertwinement of beauty, femininity and
          repulsion, while applying empathy and softness as their working
          method.
          <br />
          <br />
          Their first installation was exhibited in a group exhibition UNFOLD at
          Finnish Museum of Photography in spring 2020. Currently the duo is
          pursuing MA in Photography at Aalto University School of Art, Design
          and Architecture in Helsinki, Finland.
          <br />
          <br />
          <a
            href="http://www.lindalehtovirta.com"
            className="gurls-lehtovirta-homepage"
          >
            www.lindalehtovirta.com
          </a>
          <br />
          <a
            href="http://www.emiliapennanen.com"
            className="gurls-pennanen-homepage"
          >
            www.emiliapennanen.com
          </a>
        </div>
        <img
          src="/Publications/GURLS/GURLS_005.jpg"
          alt="Gurls-005"
          className="gurls-005"
        />
        <img
          src="/Publications/GURLS/GURLS_006.jpg"
          alt="Gurls-006"
          className="gurls-006"
        />
      </div>
    </div>
  );
};

export const HalizYosef = () => {
  return (
    <div className="yosef-container mobile-container">
      <div className="story-title-container">
        <div className="creator">Haliz Yosef</div>
        <div className="title">GAZE</div>
      </div>
      <div className="video-container h-center">
        <ReactPlayer
          url="Publications/HalizYosef/Yosef_001.mov"
          controls={true}
          width="100%"
          height="100%"
        />
        <div className="yosef-video-legend" id="mobile-padding">
          In this moving image work, Haliz Yosef depicts the structure of the
          gaze. Specifically the role of society and it’s ongoing gaze directed
          to different minorities existing in that particular society and
          culture. How people define social norms and what does integration in
          its essence mean.
          <br />
          <br />
          The lights are on an ongoing hunt for discipline and measurement for
          fitting people in a frame which in their view should be serving. For
          Haliz home is an energy force. You cannot destroy it, it will simply
          always change its form. Therefore always existing.
        </div>
      </div>
      <img
        src="/Publications/HalizYosef/Yosef_002.jpg"
        alt="Yosef-002"
        className="yosef-002"
        id="mobile-padding"
      />
      <div className="yosef-02-text" id="mobile-padding">
        Fine artist Haliz Yosef explores the ever-changing cultural identity in
        her moving image work. How languages intertwine within each other
        forming a new language and thus cultures mixing within the western
        society. Questioning stereotypes by showing a particular intimacy from
        her family life. Haliz also works with memories and her life growing up
        in Finland and creating moving image and sound installations.
        <br />
        <br />
        Haliz Yosef studies MFA in the Finnish Academy of Fine Arts. Her works
        have been shown in Finland, Sweden and South Korea.
      </div>
    </div>
  );
};

export const JuuliaKraft = () => {
  return (
    <div className="kraft-container mobile-container">
      <div className="story-title-container">
        <div className="creator">Juulia Kraft</div>
        <div className="title">
          DIY CLAY SOAP WITH ALOE VERA AND COCONUT OIL
        </div>
      </div>
      <img
        src="/Publications/JuuliaKraft/Kraft_001.jpg"
        alt="kraft-001"
        className="kraft-001"
        id="mobile-padding"
      />
      <div className="kraft-text-1" id="mobile-padding">
        Making your own soaps is fun, rewarding, and has never been easier. A
        variety of melt & pour bases are simple, safe to use and available
        online, just choose a base suited for your needs. You can also add any
        essential oils, botanicals, clay, activated charcoal, different carrier
        oils, honey, even salt if you wish. (Stephenson melt & pour bases are
        good quality and reasonably priced.)
        <br />
        <br />
        This recipe is for a clay soap with Aloe Vera base for that extra
        calming effect. The clay forms a film on top of skin making it suitable
        for shaving aid, although, a disclaimer: there has been some debate over
        whether or not it dulls the blades. Aloe Vera base soothes the skin
        reducing potential irritation. You can use any type of clay you wish, I
        have used a mixture of red clay and bentonite clay, which gives these
        soaps their lovely dusky pink look. Add a few drops of castor oil for
        extra creamy, rich lather
      </div>
      <div className="kraft-02-03-container">
        <div className="kraft-02-container">
          <img
            src="/Publications/JuuliaKraft/Kraft_002.jpg"
            alt="kraft-002"
            className="kraft-002"
            id="mobile-padding"
          />
          <div className="kraft-text-2" id="mobile-padding">
            This recipe makes 3 soaps in a large ice-cube silicone tray
            <br />
            <br />
            -500g of melt & pour soap base
            <br />
            -cutting board and a knife
            <br />
            -silicone baking mold, muffin tin or a mold for large ice cubes
            <br />
            -double boiler, DIY double boiler or a microwave
            <br />
            -2 tbs clay powder, any type
            <br />
            -4 tbs coconut oil
            <br />
            <br />
            -castor oil (optional), a few drops
            <br />
            -essential oils (optional), a few drops
          </div>
        </div>
        <img
          src="/Publications/JuuliaKraft/Kraft_003.jpg"
          alt="kraft-003"
          className="kraft-003"
        />
      </div>
      <div className="kraft-04-container">
        <img
          src="/Publications/JuuliaKraft/Kraft_004.jpg"
          alt="kraft-004"
          className="kraft-004"
          id="mobile-padding"
        />
      </div>
      <div className="kraft-instruction-container kraft-instruction-1">
        <img
          src="/Publications/JuuliaKraft/Kraft_005.jpg"
          alt="kraft-005"
          className="kraft-005"
        />
        <div className="kraft-instruction-text" id="mobile-padding">
          1. Manoeuvre the soap out of the tub by sticking it in hot water for a
          minute before opening the package. Chop the soap in little cubes of
          approx. 1-2cm diameter and place in a jug.
        </div>
      </div>
      <div className="kraft-instruction-container kraft-instruction-2">
        <img
          src="/Publications/JuuliaKraft/Kraft_006.jpg"
          alt="kraft-006"
          className="kraft-006"
        />
        <div className="kraft-instruction-text" id="mobile-padding">
          2. Either microwave the base in 30sec intervals until melted or place
          the jug in a pan of boiling water, boil and gently stir until melted.
          Avoid stirring too vigorously if you are bothered by the little air
          bubbles. They will appear to the top surface of the soap after
          pouring. Personally, I don’t mind them.
        </div>
      </div>
      <div className="kraft-instruction-container kraft-instruction-3">
        <img
          src="/Publications/JuuliaKraft/Kraft_007.jpg"
          alt="kraft-007"
          className="kraft-007"
        />
        <div className="kraft-instruction-text" id="mobile-padding">
          3. Melt the coconut oil with your chosen method.
        </div>
      </div>
      <div className="kraft-instruction-container kraft-instruction-4">
        <img
          src="/Publications/JuuliaKraft/Kraft_008.jpg"
          alt="kraft-008"
          className="kraft-008"
        />
        <div className="kraft-instruction-text" id="mobile-padding">
          4. Add clay through a sieve, stir well. You can also add the clay
          directly onto the soap base, but it might get clumpy.
          <br />
          <br />
          5. Add castor oil (optional) and essential oils (optional) to the
          coconut oil.
          <br />
          <br />
          6. Pour the coconut oil mix into the hot, melted soap base and stir
          until smooth.
        </div>
      </div>
      <div className="kraft-09-11-container">
        <div className="kraft-09-10-container">
          <img
            src="/Publications/JuuliaKraft/Kraft_009.jpg"
            alt="kraft-009"
            className="kraft-009"
          />
          <div className="kraft-09-text" id="mobile-padding">
            7. Pour the mix into the moulds and let them sit for at least two
            hours.
          </div>
          <img
            src="/Publications/JuuliaKraft/Kraft_010.jpg"
            alt="kraft-010"
            className="kraft-010"
          />
          <div className="kraft-10-text" id="mobile-padding">
            8. Take out of the molds, wrap the excess in baking paper and store
            in an airtight box.
          </div>
        </div>
        <div className="kraft-11-container">
          <img
            src="/Publications/JuuliaKraft/Kraft_011.jpg"
            alt="kraft-011"
            className="kraft-011"
          />
          <div className="kraft-11-text" id="mobile-padding">
            <b>Juulia Kraft</b> is a Glasgow (UK) based Jack of many trades. She
            holds a BA degree in fashion business & design and is currently
            studying a MSc in environmental studies and sustainability. Juulia
            is passionate about DIY, beautiful objects, nature and art.
          </div>
        </div>
      </div>
    </div>
  );
};

export const LandysRoimola = () => {
  return (
    <div className="roimola-container mobile-container">
      <div className="story-title-container">
        <div className="creator">Landys</div>
        <div className="title">Anthro-po-scene</div>
      </div>
      <div className="video-container h-center">
        <ReactPlayer
          url="Publications/LandysRoimola/Roimola_001.mp4"
          controls={true}
          width="100%"
          height="100%"
        />
        <div className="roimola-video-legend" id="mobile-padding">
          <div className="roimola-video-text h-center">
            Anthro-po-scene explores the inner relationship of the artist with
            nature and the relationship of nature with her. The human is the
            most violent species on earth. The violence towards nature, other
            species, and its own members are constant and devastating. Mirroring
            one's own actions, thoughts and privileges is painful and lonely
            work and it would be easier to look away. The hardest thing is to
            see and keep looking.
          </div>
        </div>
      </div>
      <img
        src="/Publications/LandysRoimola/Roimola_002.jpg"
        alt="roimola-002"
        className="roimola-002 h-center"
        id="mobile-padding"
      />
      <div className="roimola-text h-center" id="mobile-padding">
        Landys Roimola (1992, Bogota) creates socially committed art that is
        based on observations and examination of materials, society, and
        challenges that the human-caused global warming brings. In her opinion,
        the role of an artist is to inspire and encourage people in the face of
        major global problems.
      </div>
    </div>
  );
};

export const MaisaImmonen = () => {
  return (
    <div className="immonen-container mobile-container">
      <div className="story-title-container">
        <div className="creator">Maisa Immonen</div>
        <div className="title">Siimes</div>
      </div>
      <img
        src="/Publications/MaisaImmonen/Immonen_001.jpg"
        alt="immonen-001"
        className="immonen-001 h-center"
      />
      <div className="video-container h-center">
        <ReactPlayer
          url="Publications/MaisaImmonen/Immonen_002.mp4"
          controls={true}
          width="100%"
          height="100%"
        />
        <div className="immonen-video-legend" id="mobile-padding">
          <div className="immonen-text" id="immonen-mobile-text">
            Maisa Immonen is a Helsinki-based media artist and visualist. In her
            art she creates characters and scenarios, through which she explores
            emotions and identities. Her interests include the thematics of
            shame and self-assurance and how these are associated with the
            representation of self and performativeness. There is something
            human and sensitive in the worlds and creatures she creates, as well
            as something strange and creepy. Maisa draws inspiration from
            vibrant colors and organic textures. She uses 3D- technology to
            illustrate the shapes and forms that are created by the
            uncontrollable force of nature.
          </div>
        </div>
      </div>
    </div>
  );
};

export const YauTasula = () => {
  return (
    <div className="yautasula-container mobile-container">
      <div className="story-title-container">
        <div className="creator">Man Yau & Maria Tasula</div>
        <div className="title">Days of our Lives</div>
      </div>
      <div className="yautasula-text-1 h-center" id="mobile-padding">
        During the evenings I read books and sometimes one of them is so good
        that I finish it by three. Then the sun rises and it's day again.
      </div>
      <img
        src="/Publications/YauTasula/YauTasula_001.jpg"
        alt="yautasula-001"
        className="yautasula-001"
      />
      <img
        src="/Publications/YauTasula/YauTasula_002.jpg"
        alt="yautasula-002"
        className="yautasula-002"
      />
      <div className="yautasula-text-2" id="mobile-padding">
        In the morning I can’t decide which one is more toxic, the resin or the
        soldering of the reflecting material I use. The tips of my fingers start
        to fill up with small cuts. My iPhone doesn’t recognize me. All the
        pieces must fit together in order for me to tie them up in the end.
      </div>
      <div className="yautasula-text-3" id="mobile-padding">
        I observe how the sun moves and by now I am fully aware of where it is
        each passing moment even though I still can’t fathom how it stays in
        place while the earth turns. Between twelve and five the balcony is
        blazing hot.
      </div>
      <img
        src="/Publications/YauTasula/YauTasula_003.jpg"
        alt="yautasula-003"
        className="yautasula-003"
      />
      <img
        src="/Publications/YauTasula/YauTasula_004.jpg"
        alt="yautasula-004"
        className="yautasula-004 h-center"
      />
      <div className="yautasula-text-4 h-center" id="mobile-padding">
        It can take an hour to polish a piece of glass the size of a thumb. At
        some point I get bored. Maybe I want to experience everything faster.
      </div>
      <div className="yautasula-05-container">
        <div className="yautasula-text-5" id="mobile-padding">
          I follow how the first leaves unfold through the curtain and at first
          move low, like children playing behind the window. From looking inside
          to the other side there is a world and the people outside can see me.
        </div>
        <img
          src="/Publications/YauTasula/YauTasula_005.jpg"
          alt="yautasula-005"
          className="yautasula-005"
        />
      </div>
      <img
        src="/Publications/YauTasula/YauTasula_006.jpg"
        alt="yautasula-006"
        className="yautasula-006"
      />
      <div className="yautasula-text-6" id="mobile-padding">
        There are plants that hurt your palms when you try to pull them from the
        soil. At eight I call my friend and pack a pair of scissors. Between the
        pages of a book the flowers don’t live like they used to.
      </div>
      <img
        src="/Publications/YauTasula/YauTasula_007.jpg"
        alt="yautasula-007"
        className="yautasula-007"
      />
      <div className="yautasula-text-7" id="mobile-padding">
        The evening is always lighter than the morning and seems to last longer.
        Sometimes before it ends, the sky is pink and my neighbour takes their
        Tesla out for a ride in the night.
      </div>
      <div className="yautasula-text-8" id="mobile-padding">
        Sculptures by Man Yau:
        <br />
        <br />
        Untitled (corset)
        <br />
        2020
        <br />
        Tiffany glass, metal, pressed flowers, ribbon
        <br />
        34 cm x 27 cm
        <br />
        <br />
        Untitled (mirror)
        <br />
        2020
        <br />
        Mirror, metal
        <br />
        17 cm x 16 cm x 11 cm
        <br />
        <br />
        Photography by Maria Tasula:
        <br />
        Espoo, 2020.
      </div>
      <div className="yautasula-text-9 h-center" id="mobile-padding">
        Man Yau is a Helsinki based artist. Yau’s works thread a balance between
        traditional methods and craftsmanship and industrial, detailed form and
        quality. Yau uses demanding materials such as stone and glass that
        require slow and delicate techniques of carving and cutting. She has
        exhibited in solo and group shows in Finland and USA among others.
        <br />
        <br />
        Yau graduated with a Master’s degree from Aalto University of Art and
        Design specialising in ceramics and glass design. Currently she is
        deepening her practice at the Master’s program in sculpture at the The
        Academy of Fine Arts in Helsinki.
        <br />
        <br />
        <a href="https://www.manyau.fi" className="yau-homepage">
          www.manyau.fi
        </a>
        <br />
        <br />
        <br />
        <br />
        Maria Tasula is a photographer working in the field of fashion
        communication. Interested in the luxury of wasting time and exploring
        the world of immediate surroundings, her work is often process based and
        incorporates text.
        <br />
        <br />
        Tasula studied BA Fashion Communication at Central Saint Martins and MA
        Photography at ECAL in Switzerland. Her work has been featured in Dazed
        Digital, SSAW and Purple.fr and most recently in the Bianca e Blu Monica
        Bolzoni monograph published by Rizzoli. Copyright 2020 ISLA Journal
        <br />
        <br />
        <a href="https://www.mariatasula.com" className="tasula-homepage">
          www.mariatasula.com
        </a>
      </div>
    </div>
  );
};

export const RonyaHirsma = () => {
  return (
    <div className="hirsma-container mobile-container">
      <div className="story-title-container">
        <div className="creator">Ronya Nadine Hirsma</div>
        <div className="title">HOW DO THE WALLS GET STAINED</div>
      </div>
      <div className="hirsma-01-02-container h-center">
        <img
          src="/Publications/RonyaHirsma/Hirsma_001.jpg"
          alt="Hirsma-001"
          className="hirsma-001"
        />
        <div className="hirsma-01-02-text-container" id="mobile-padding">
          <div className="hirsma-text-1">
            Bedtime stories, reassuring
            <br />
            <i>
              Once upon a time
              <br />
              There was a mind
            </i>
            <br />
            it was
            <br />
            bread crumbs
            <br />
            on a white shirt
          </div>
          <div className="hirsma-text-2">
            It was
            <br />
            ping pong! on a wall!
            <br />
            that was meant to be white
            <br />
            how do the walls
            <br />
            get stained
            <br />I have avoided
            <br />
            touching them
            <br />I have twenty four square meters
          </div>
          <div className="hirsma-text-3">
            To find
            <br />
            <i>my way in the morning</i>
            <br />
            bread crumbs
            <br />
            on white sheets
          </div>
          <div className="hirsma-text-4">
            It derives,
            <br />
            rides
            <br />
            but does not <i>settle</i>
            <br />
            peek-a-poo-ping-pong
            <br />
            on a wall
          </div>
          <div className="hirsma-text-5">
            How do the walls
            <br />
            get stained
            <br />I have avoided
            <br />
            touching them
          </div>
        </div>
        <img
          src="/Publications/RonyaHirsma/Hirsma_002.jpg"
          alt="Hirsma-002"
          className="hirsma-002"
        />
      </div>
      <div className="hirsma-text-6" id="mobile-padding">
        <i>
          Dérive, is “an unplanned journey through a landscape, in which one
          drops their everyday relations and let themselves be drawn by the
          <br />
          attractions of the terrain and the encounters they find there”.
        </i>
        <br />
        <br />
        <br />
        To dérive <i>in</i> one’s <i>mind</i> is
        <br />
        navigating
        <br />
        the <i>psychogeography</i>, landscape
        <br />
        resemblance
        <br />
        stains on
        <br />a wall
        <br />
        <br />
        In the night
        <br />I mumble
        <br />
        <i>sleep next to me</i>
        <br />
        my limbs give
        <br />
        <i>in</i>
        <br />I have plenty! twenty
        <br />
        four square meters in my bed
        <br />
        <br />
        Bread crumbs
        <br />
        on a wall
        <br />
        that was meant to be white
        <br />
        <i>
          how do the walls get stained
          <br />I have avoided
          <br />
          touching them I have
        </i>
        <br />
        <br />
        Bedtime stories, reassuring
        <br />
        <i>Hansel and Gretel</i>
        <br />
        the woods, how to return from
        <br />
        crumbled bread
        <br />
        to find my way out of the bed
        <br />
        in the morning
        <br />I have plenty! twenty
        <br />
        four square meters
      </div>
      <div className="hirsma-text-7" id="mobile-padding">
        Ronya Nadine Hirsma is an art practitioner and writer with a background
        in anthropology. She describes her practice very vaguely as visual
        examination and narrating, in which she often applies elements of both
        documentary and fiction. In her exploration, she mainly uses the mediums
        of writing and photography. <br />
        <br />
        <a
          href="https://www.instagram.com/ronyanadine"
          className="hirsma-homepage"
        >
          @ronyanadine
        </a>
      </div>
      <img
        src="/Publications/RonyaHirsma/Hirsma_003.jpg"
        alt="Hirsma-003"
        className="hirsma-003"
      />
    </div>
  );
};

export const SidonieRonfard = () => {
  return (
    <div className="ronfard-container mobile-container">
      <div className="story-title-container">
        <div className="creator">Sidonie Ronfard</div>
      </div>
      <div className="ronfard-01-02-container">
        <div className="ronfard-01-02-text" id="mobile-padding">
          Home could be with your smile or your breath next to mine. It’s where
          I find myself, within me, with a thought, a smell, a light, or a song.
          Home is such a broad subject and it is a feeling more than a place. I
          have asked people who my heart beats for, my dear friends, to pose for
          me and asked them to translate, feelings. My pictures are between
          spontaneity and poser: the flames, her legs, her gaze, those elements
          are building, day by day my home.
        </div>
        <img
          src="/Publications/SidonieRonfard/Ronfard_001.jpg"
          alt="Ronfard-001"
          className="ronfard-001"
        />
        <img
          src="/Publications/SidonieRonfard/Ronfard_002.jpg"
          alt="Ronfard-002"
          className="ronfard-002"
        />
      </div>
      <div className="ronfard-03-05-06-container">
        <img
          src="/Publications/SidonieRonfard/Ronfard_003.jpg"
          alt="Ronfard-003"
          className="ronfard-003 h-center"
        />

        <img
          src="/Publications/SidonieRonfard/Ronfard_005.jpg"
          alt="Ronfard-005"
          className="ronfard-005"
        />
        <img
          src="/Publications/SidonieRonfard/Ronfard_006.jpg"
          alt="Ronfard-006"
          className="ronfard-006"
        />
      </div>
      <div className="ronfard-04-07-container">
        <img
          src="/Publications/SidonieRonfard/Ronfard_004.jpg"
          alt="Ronfard-004"
          className="ronfard-004"
        />
        <img
          src="/Publications/SidonieRonfard/Ronfard_007.jpg"
          alt="Ronfard-007"
          className="ronfard-007"
          id="mobile-padding"
        />
      </div>
      <img
        src="/Publications/SidonieRonfard/Ronfard_008.jpg"
        alt="Ronfard-008"
        className="ronfard-008"
      />
      <img
        src="/Publications/SidonieRonfard/Ronfard_009.jpg"
        alt="Ronfard-009"
        className="ronfard-009"
      />
      <img
        src="/Publications/SidonieRonfard/Ronfard_010.jpg"
        alt="Ronfard-010"
        className="ronfard-010"
      />
      <img
        src="/Publications/SidonieRonfard/Ronfard_011.jpg"
        alt="Ronfard-011"
        className="ronfard-011"
      />
      <img
        src="/Publications/SidonieRonfard/Ronfard_012.jpg"
        alt="Ronfard-012"
        className="ronfard-012"
      />
      <div className="ronfard-12-text" id="mobile-padding">
        Sidonie Ronfard is a storyteller who uses fire and it’s consumption, as
        the main topic of her research. It’s all about catching fire, lighting
        around you, feeling your warmth, then letting it go and accepting that
        it’s all gone and starting another fire. This metaphorical reading of
        fire is always present, specifically around relationships in her
        pictures. She tries to express how beauty burns us just like a branch
        heating our face, and sometimes beauty bites us just like a wonderful
        wild wolf, right in our throat, like Christian Bobin in « Folle allure »
        would say.
        <br />
        <br />
        <a
          href="https://www.instagram.com/sidonie_r"
          className="ronfard-homepage"
        >
          @sidonie_r
        </a>
      </div>
    </div>
  );
};

export const Silander = () => {
  return (
    <div className="silander-container mobile-container">
      <div className="story-title-container">
        <div className="creator">Sanna Silander & Anu Uusipalkamo</div>
        <div className="title">Dress Up</div>
      </div>
      <img
        src="/Publications/Silander/Silander_001.jpg"
        alt="silander-001"
        className="silander-001 h-center"
      />
      <img
        src="/Publications/Silander/Silander_003.jpg"
        alt="silander-003"
        className="silander-003"
      />
      <img
        src="/Publications/Silander/Silander_002.jpg"
        alt="silander-002"
        className="silander-002"
      />

      <img
        src="/Publications/Silander/Silander_004.jpg"
        alt="silander-004"
        className="silander-004"
      />
      <img
        src="/Publications/Silander/Silander_005.jpg"
        alt="silander-005"
        className="silander-005 h-center"
      />
      <img
        src="/Publications/Silander/Silander_006.jpg"
        alt="silander-006"
        className="silander-006 h-center"
      />
      <img
        src="/Publications/Silander/Silander_007.jpg"
        alt="silander-007"
        className="silander-007"
      />
      <img
        src="/Publications/Silander/Silander_008.jpg"
        alt="silander-008"
        className="silander-008"
      />
      <img
        src="/Publications/Silander/Silander_009.jpg"
        alt="silander-009"
        className="silander-009"
      />
      <img
        src="/Publications/Silander/Silander_010.jpg"
        alt="silander-010"
        className="silander-010"
      />
      <div className="silander-11-container">
        <div className="silander-11-text" id="mobile-padding">
          Sanna Silander is Freelancer Stylist & Co - Founder and Fashion Editor
          for ISLA Journal. She has wide experience in styling both editorial
          and commercial projects w. clients and publications including Warner
          Music, Universal Music, Lovia Collection, Sk̊al Helsinki, JANE Magazine
          and Flanelle Magazine.
          <br />
          <a href="http://sannasilander.com" className="silander-homepage">
            sannasilander.com
          </a>
          <br />
          <br />
          Anu Uusipulkamo:{" "}
          <i>
            As a make-up artist and a hair stylist I thrive when I’m given the
            freedom to create from my heart. Being from Lapland, I draw
            inspiration from nature and its colours and more than anything I
            value getting to know my customers for who they are. Last summer I
            was the lead make-up artist for a movie directed by Hamy Ramazan and
            I especially appreciated, and found one of my greatest
            accomplishments in, the relationships I established with the child-
            actors and actresses in the movie.
          </i>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          Clothes:
          <br />
          Zimmermann
          <br />
          ATP Atelier
          <br />
          Self- Portrait
          <br />
          Ulla Johnson
          <br />
          <br />
          Thank you My o My
        </div>
        <div className="silander-tab">
          <div className="silander-tab-left" id="silander-mobile-tab">
            Styling
            <br />
            <br />
            <br />
            Make-up and Hair
            <br />
            <br />
            <br />
            Photography
            <br />
            <br />
            <br />
            Model
          </div>
          <div className="silander-tab-right" id="silander-mobile-tab">
            Sanna Silander
            <br />
            <a
              href="https://www.instagram.com/silandersanna/"
              className="silander-insta"
            >
              @silandersanna
            </a>
            <br />
            <br />
            Anu Uusipulkamo
            <br />
            <a
              href="https://www.instagram.com/anu.uusipulkamo/"
              className="uusipulkamo-insta"
            >
              @anu.uusipulkamo
            </a>
            <br />
            <br />
            Aya Brace
            <br />
            <a
              href="https://www.instagram.com/aya.brace/"
              className="brace-insta"
            >
              @aya.brace
            </a>
            <br />
            <br />
            Regine @ Paparazzi
            <br />
            <a
              href="https://www.instagram.com/reginemorales20/"
              className="regine-insta"
            >
              @reginemorales20
            </a>
          </div>
        </div>
        <img
          src="/Publications/Silander/Silander_011.jpg"
          alt="silander-011"
          className="silander-011"
        />
      </div>
      <img
        src="/Publications/Silander/Silander_012.jpg"
        alt="silander-012"
        className="silander-012"
      />
    </div>
  );
};

export const TaikaMannila = () => {
  return (
    <div className="mannila-container mobile-container" id="mobile-padding">
      <div className="story-title-container">
        <div className="creator">Taika Mannila</div>
        <div className="title">LOCKDOWN LOVE</div>
      </div>
      <div className="mannila-text-1">
        I look at other women <br />
        I read other women
        <br />
        I listen to other women
        <br />
        While I just lay on my floor for a week
        <br />
        While I lay on my floor for months
        <br />
        I look at other women for answers
        <br />
        While I lay on my floor for what feels like a year
        <br />
        I look around me and at least the flowers in my room are dying
        beautifully
        <br />
        <br />
        I lay on my floor and screenshot the world to pieces with my phone
        <br />
        And after a while my anxiety turns into anger
        <br />
        Lana Del Rey has lockdown cabin fever
        <br />
        Grimes is selling her soul
        <br />
        A rich influencer writes about heaven and hell
        <br />
        I’m melting in my anger and drowning in my dreams
        <br />
        Like Amanda Seyfrieds character Karen Smith in the movie Mean Girls, I,
        too have a fifth sense
        <br />
        I always feel it in my body when jealousy takes over
        <br />
      </div>
      <img
        src="/Publications/TaikaMannila/Mannila_001.jpg"
        alt="mannila-001"
        className="mannila-001"
      />
      <div className="mannila-text-2">
        I lay on my floor and really try to love other women
        <br />
        I watch a Courtney Love documentary on Youtube and I LOVE Love when she
        says:
        <br />
        <i>“If I was not a bit mad I would be a worthless rockstar”</i>
        <br />
        I read a survival guide written by someone who used to live at the
        Chateau Marmont
        <br />I steal this thought:{" "}
        <i>“People have forgotten how to be legendary.”</i>
        <br />
        I search for inspirational quotes about reinvention and rebirth
        <br />
        It’s embarrassing but desperate times call for desperate pleasures
        <br />
        The ghosts in my closet remind me of what I used to be
        <br />
        And suddenly I remember my worst outfit: a shirt that said Kiss the boys
        and make them cry
        <br />
        I wore it once to my gynecologist appointment and after that I never
        wore it again
        <br />
        <br />
        I lay on my floor and watch how jewellery made out of coloured ice melt
        on a models body
        <br />
        I watch a documentary about Toni Morrison and write down some of her
        famous quotes on a post-it:
        <br />
        <i>
          “If there's a book that you want to read, but it hasn't been written
          yet, then you must write it.”
          <br />
          “You wanna fly, you got to give up the shit that weighs you down.”
        </i>
        <br />
        I close my eyes and when I open them again I have watched every Charlies
        Angels movie ever made
        <br />
        My phone is full of paparazzi pictures of Cameron Diaz and Drew
        Barrymore drinking champagne on the street
        <br />
      </div>
      <img
        src="/Publications/TaikaMannila/Mannila_002.jpg"
        alt="mannila-002"
        className="mannila-002"
      />
      <div className="mannila-text-3">
        I lay on my floor and waste everything that feels like time on Instagram
        <br />
        A pregnant girl I know writes that she’s trying to create a perfect
        pregnancy wardrobe
        <br />
        I wonder if that is something I will ever try to do?
        <br />
        All of my friends post pictures of things they miss, pictures of the
        Lost World <br />
        I post a picture of myself
        <br />
        <br />
        I lay on my floor and chat with a friend about love, she says she dreams
        of easy relationships
        <br />
        I text her back: LOVE IS NOT EASY
        <br />
        But afterwards I feel weird. What do I know about love?
        <br />
        I watch the Courtney Love doc again
        <br />
        In the comments section I find definitions for Love:
        <br />
        Love is a pretty mess
        <br />
        Love is a woman who wears many masks
        <br />
        Love is ruthless, but not all bad, and certainly quotable
        <br />
        Love is batshit crazy and when she deviates they call her Satan
        <br />
        Love says she has found Buddhism and is chanting for Lindsay Lohan and
        Kate Moss
        <br />
        <br />
        There is one moment in the documentary that I watch again and again and
        again
        <br />
        Love is in her studio recording music and she sings:
        <br />
        <i>
          “I know that I'm a hungry lost girl
          <br />
          But please stick around and I'll build you a world”
        </i>
      </div>
      <div className="mannila-text-4">
        Taika Mannila is an Helsinki based artist and a wannabe writer who is
        good at panicking and bad at committing. She has a love/hate
        relationship with working from home and her greatest goal in life is to
        be able to call a five star hotel her home office.
        <br />
        <br />
        <a
          href="https://www.instagram.com/magicmannila"
          className="mannila-homepage"
        >
          @magicmannila
        </a>
      </div>
    </div>
  );
};

export const VanessaForsten = () => {
  return (
    <div className="forsten-container mobile-container">
      <div className="story-title-container">
        <div className="creator">Vanessa Forstén</div>
        <div className="title">Revir</div>
      </div>
      <img
        src="/Publications/VanessaForsten/Forsten_001.jpg"
        alt="forsten-001"
        className="forsten-001 h-center"
      />
      <img
        src="/Publications/VanessaForsten/Forsten_002.jpg"
        alt="forsten-002"
        className="forsten-002"
      />
      <div className="forsten-03-04-container">
        <img
          src="/Publications/VanessaForsten/Forsten_003.jpg"
          alt="forsten-003"
          className="forsten-003"
        />
        <img
          src="/Publications/VanessaForsten/Forsten_004.jpg"
          alt="forsten-004"
          className="forsten-004"
          id="mobile-padding"
        />
      </div>
      <img
        src="/Publications/VanessaForsten/Forsten_005.jpg"
        alt="forsten-005"
        className="forsten-005 h-center"
      />
      <div className="forsten-text" id="mobile-padding">
        Vanessa Forstén’s (b. 1990) self-portrait series Revir is a photographic
        exploration into a young woman’s identity. The series addresses how a
        young woman experiences her own body, mind and the physical home she is
        living in. Home is our own empire, in which we are safe from the
        surrounding world. Home is the only place where we are free to be who we
        really are.
        <br />
        <br />
        The self-portrait series was shot at Forstén’s own home when she was
        completely alone. The longing to be alone speaks volumes about today’s
        individualism and the independence of other people. The series aims to
        investigate how easily a person can isolate herself into her own state
        of mind and emotions, when she moves to live alone for the first time.
        The end of the childhood and the beginning of the adulthood is a
        challenging phase, as it tests young adults how to balance life between
        independence and loneliness.
        <br />
        <br />
        Forstén is interested in the relationship between the model and the
        photographer. What happens when the photographer and the person being
        photographed are the same person? The objectifying gaze shifts
        completely when the photographed model is the photographer herself.
        Being alone in the safety of one’s own home helps one to communicate to
        oneself better. It makes one understand one’s own feelings and state of
        mind, and accept who we are without the burden of pretending for others.
        Even though the photographs are intimate, Forstén wants to share her
        personal experiences of shallow feelings to the more complex and
        oppressive emotions. In this work Forstén is interested in bringing the
        hidden and often unspoken emotions to the view for a larger audience.
        <br />
        <br />
        <a href="https://www.vanessaforsten.com" className="forsten-homepage">
          www.vanessaforsten.com
        </a>
      </div>
      <img
        src="/Publications/VanessaForsten/Forsten_006.jpg"
        alt="forsten-006"
        className="forsten-006"
      />
    </div>
  );
};
