import React, { useState } from "react";
import "./frontpage.css";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";

const Frontpage = (props) => {
  const [index, setIndex] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      index === 7 ? setIndex(1) : setIndex(index + 1);
    }, 3000);
  });

  return (
    <div className="frontpage">
      <div className="frontpage-title">ISLA Journal</div>
      <div className="frontpage-legend">issue two</div>
      <img
        src={"/Frontpage/etusivu0" + index + ".jpg"}
        alt="frontpage"
        className="frontpage-image"
      />
      <div
        className="frontpage-enter"
        onClick={() => {
          props.setIsClicked(true);
          props.setIsReady(false);
          setTimeout(() => props.setIsReady(true), 1000);
        }}
      >
        Enter
      </div>
    </div>
  );
};

export default Frontpage;
