import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import "./main.css";
import Loading from "../Loading/Loading";
import Publication from "./Publication";
import database from "./database.json";
import {
  AnnabelKajetski,
  AyaBrace,
  ChristineValtonen,
  GURLS,
  HalizYosef,
  MaisaImmonen,
  RonyaHirsma,
  SidonieRonfard,
  TaikaMannila,
  VanessaForsten,
  EevaSuutari,
  EmmaSarpaniemi,
  JuuliaKraft,
  LandysRoimola,
  YauTasula,
  Silander,
} from "../Publications/Publications";
import Contributors from "../Contributors/Contributors";
import Frontpage from "../Frontpage/Frontpage";

const Main = () => {
  const [coords, setCoords] = useState([]);
  const [isReady, setIsReady] = useState(true);
  const [viewContributors, setViewContributors] = useState(false);
  const [viewAbout, setViewAbout] = useState(false);
  const [viewContact, setViewContact] = useState(false);
  const [viewStory, setViewStory] = useState(false);
  const [activeStory, setActiveStory] = useState("");
  const [showHamburger, setShowHamburger] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  // GET RANDOM COORDINATES FOR TITLES ON MOUNT
  useEffect(() => {
    const coordsArray = [];
    let coords = {};
    let overlapping = false;
    const protection = 100000;
    let counter = 0;

    while (coordsArray.length < 16 && counter < protection) {
      coords = {
        x: Math.random() * 100,
        y: Math.random() * 100,
      };
      overlapping = false;

      for (let i = 0; i < coordsArray.length; i++) {
        if (
          Math.abs(coordsArray[i].x - coords.x) < 15 &&
          Math.abs(coordsArray[i].y - coords.y) < 15
        ) {
          overlapping = true;
          break;
        }
      }

      if (!overlapping) {
        coordsArray.push(coords);
      }
      counter++;
    }
    setCoords(coordsArray);
    setTimeout(() => {
      setIsReady(true);
    }, 1000);
  }, []);

  // SHOW PUBLICATION
  const showPublication = (p) => {
    switch (p) {
      case "annabelkajetski":
        return <AnnabelKajetski />;
      case "ayabrace":
        return <AyaBrace />;
      case "christinevaltonen":
        return <ChristineValtonen />;
      case "eevasuutari":
        return <EevaSuutari />;
      case "emmasarpaniemi":
        return <EmmaSarpaniemi />;
      case "gurls":
        return <GURLS />;
      case "halizyosef":
        return <HalizYosef />;
      case "juuliakraft":
        return <JuuliaKraft />;
      case "landysroimola":
        return <LandysRoimola />;
      case "maisaimmonen":
        return <MaisaImmonen />;
      case "yautasula":
        return <YauTasula />;
      case "ronyahirsma":
        return <RonyaHirsma />;
      case "sidonieronfard":
        return <SidonieRonfard />;
      case "silander":
        return <Silander />;
      case "taikamannila":
        return <TaikaMannila />;
      case "vanessaforsten":
        return <VanessaForsten />;
      default:
        break;
    }
  };

  // TOGGLE COMPONENT VIEWS
  const togglePage = (page) => {
    switch (page) {
      case "about":
        setViewAbout(true);
        setViewContributors(false);
        setViewContact(false);
        setViewStory(false);
        break;
      case "contributors":
        setViewAbout(false);
        setViewContributors(true);
        setViewContact(false);
        setViewStory(false);
        break;
      case "contact":
        setViewAbout(false);
        setViewContributors(false);
        setViewContact(true);
        setViewStory(false);
        break;
      case "home":
        setViewAbout(false);
        setViewContributors(false);
        setViewContact(false);
        setViewStory(false);
        break;
      default:
        break;
    }
  };

  const contributorOnClick = (story) => {
    setActiveStory(story);
    setViewAbout(false);
    setViewContributors(false);
    setViewContact(false);
    setViewStory(true);
  };

  const shuffleArray = (originalArray) => {
    var array = [].concat(originalArray);
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  return !isReady ? (
    <Fade when={!isReady}>
      <Loading />
    </Fade>
  ) : !isClicked ? (
    <div>
      <Frontpage setIsClicked={setIsClicked} setIsReady={setIsReady} />
    </div>
  ) : (
    <div
      className="main-page"
      style={
        viewAbout || viewContact || viewContributors
          ? {
              backgroundColor: "rgb(255, 224, 173)",
            }
          : {}
      }
    >
      <div id="top-container">
        <div id="logo-container">
          <div
            id="logo-title"
            onClick={() => {
              togglePage("home");
              document.documentElement.scrollTop = 0;
            }}
          >
            ISLA Journal
          </div>
          <div id="logo-legend">issue two</div>
        </div>
        <div className="links-container">
          <div
            className="links"
            id="contributors"
            onClick={() => togglePage("contributors")}
          >
            Contributors
          </div>
          <div className="links" id="about" onClick={() => togglePage("about")}>
            About
          </div>
          <div
            className="links"
            id="contact"
            onClick={() => togglePage("contact")}
          >
            Contact
          </div>
        </div>
        <div className="hamburger-logo">
          <img
            src={showHamburger ? "/cancel.png" : "/hamburger.png"}
            alt="hamburger"
            onClick={() => setShowHamburger(!showHamburger)}
          />
        </div>
        {showHamburger ? (
          <div className="hamburger-menu">
            <div className="hamburger-links-container">
              <div
                className="hamburger-links"
                id="contributors"
                onClick={() => {
                  togglePage("contributors");
                  setShowHamburger(false);
                }}
              >
                Contributors
              </div>
              <div
                className="hamburger-links"
                id="about"
                onClick={() => {
                  togglePage("about");
                  setShowHamburger(false);
                }}
              >
                About
              </div>
              <div
                className="hamburger-links"
                id="contact"
                onClick={() => {
                  togglePage("contact");
                  setShowHamburger(false);
                }}
              >
                Contact
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {viewContributors ? (
        <Contributors contributorOnClick={contributorOnClick} />
      ) : viewAbout ? (
        <div className="about-container">
          <div id="about-title">About</div>
          <div id="about-text">
            ISLA Journal (Ongoing Perceptions of Womanhood) is a fashion zine by
            photographer Aya Brace and stylist Sanna Silander founded in 2019.
            <br />
            <br />
            What started as a project for the love of print, turned into a
            website for issue two when the whole world was social distancing. We
            invited contributors to continue to discuss perceptions through the
            theme <b>HOME</b>.
          </div>
        </div>
      ) : viewContact ? (
        <div className="contact-container">
          <div className="contact-title">Contact</div>
          <div className="contact-tab-left">
            instagram:
            <br />
            say hello:
          </div>
          <div className="contact-tab-right">
            <a
              href="https://www.instagram.com/isla.journal/"
              className="contact-insta"
            >
              @isla.journal
            </a>
            <br />
            islajournal@gmail.com
          </div>
        </div>
      ) : (
        <div className="main-content">
          {viewStory ? (
            showPublication(activeStory)
          ) : (
            <div className="publications-container">
              {shuffleArray(database.publications).map((p, i) => {
                return (
                  <div>
                    <Publication
                      coords={coords[i]}
                      img={p.img}
                      creator={p.creator}
                      title={p.title}
                      redirect={p.redirect}
                      setActiveStory={setActiveStory}
                      setViewStory={setViewStory}
                    />
                    <div className="mobile-publication-title">{p.title}</div>
                    <div className="mobile-publication-creator">
                      {p.creator}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!viewStory ? (
            <div className="info-container">
              <div id="info-title">Isla WHO?</div>
              <div id="info-text">
                ISLA Journal (Ongoing Perceptions of Womanhood) is a fashion
                zine by photographer Aya Brace and stylist Sanna Silander
                founded in 2019.
                <br />
                <br />
                For issue two we created a website and invited contributors to
                continue to discuss perceptions through the theme HOME.
              </div>
              <div id="info-copyright">Copyright 2020 ISLA Journal</div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Main;
