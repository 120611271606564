import React from "react";
import "./contributors.css";

const Contributors = (props) => {
  return (
    <div className="contributors-container h-center">
      <div className="contributors-title">Contributors</div>
      <div className="contributors-tab-left">
        A<br />
        <b>B</b>
        <br />
        C<br />
        D<br />
        E<br />
        <b>F</b>
        <br />
        G<br />
        <b>H</b>
        <br />
        <b>I</b>
        <br />
        J<br />
        <b>K</b>
        <br />
        <br />
        <b>L</b>
        <br />
        <br />
        <b>M</b>
        <br />
        N<br />
        O<br />
        <b>P</b>
        <br />
        Q<br />
        <b>R</b>
        <br />
        <br />
        <b>S</b>
        <br />
        <br />
        <br />
        <b>T</b>
        <br />
        <b>U</b>
        <br />
        <b>V</b>
        <br />
        W<br />
        X<br />
        <b>Y</b>
        <br />
        <br />
        Z<br />
      </div>
      <div className="contributors-tab-left">
        <br />
        <span onClick={() => props.contributorOnClick("ayabrace")}>
          Brace, Aya
        </span>
        <br />
        <br />
        <br />
        <br />
        <span onClick={() => props.contributorOnClick("vanessaforsten")}>
          Forstén, Vanessa
        </span>
        <br />
        <br />
        <span onClick={() => props.contributorOnClick("ronyahirsma")}>
          Hirsma, Ronya
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("maisaimmonen")}>
          Immonen, Maisa
        </span>
        <br />
        <br />
        <span onClick={() => props.contributorOnClick("annabelkajetski")}>
          Kajetski, Annabel
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("juuliakraft")}>
          Kraft, Juulia
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("gurls")}>
          Lehtovirta, Linda
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("eevasuutari")}>
          Liedes, Eevis
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("taikamannila")}>
          Mannila, Taika
        </span>
        <br />
        <br />
        <br />
        <span onClick={() => props.contributorOnClick("gurls")}>
          Pennanen, Emilia
        </span>
        <br />
        <br />
        <span onClick={() => props.contributorOnClick("landysroimola")}>
          Roimola, Landys
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("sidonieronfard")}>
          Ronfard, Sidonie
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("emmasarpaniemi")}>
          Sarpaniemi, Emma
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("silander")}>
          Silander, Sanna
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("eevasuutari")}>
          Suutari, Eeva
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("yautasula")}>
          Tasula, Maria
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("silander")}>
          Uusipulkamo, Anu
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("christinevaltonen")}>
          Valtonen, Christine
        </span>
        <br />
        <br />
        <br />
        <span onClick={() => props.contributorOnClick("yautasula")}>
          Yau, Man
        </span>
        <br />
        <span onClick={() => props.contributorOnClick("halizyosef")}>
          Yosef, Haliz
        </span>
      </div>
    </div>
  );
};

export default Contributors;
