import React, { useState } from "react";

const Publication = (props) => {
  const [opacity, setOpacity] = useState(0);

  const style = {
    position: "absolute",
    top: props.coords.y + "%",
    left: props.coords.x + "%",
  };

  return (
    <div>
      <div className="publication" style={style}>
        <img
          src={props.img}
          alt={props.creator}
          id="pub-image"
          style={{
            opacity: opacity,
          }}
        />
        <div
          id="pub-creator"
          onMouseEnter={() => setOpacity(1)}
          onMouseLeave={() => setOpacity(0)}
          onClick={() => {
            props.setActiveStory(props.redirect);
            props.setViewStory(true);
            document.documentElement.scrollTop = 0;
          }}
          style={
            opacity === 1
              ? {
                  color: "rgb(241, 92, 65)",
                  fontWeight: "bold",
                }
              : {}
          }
        >
          {props.creator}
        </div>
      </div>
    </div>
  );
};

export default Publication;
